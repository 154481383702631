<template>
    <div class="page-contact-us g-page">
        <div class="wp">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path:'/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item>联系我们</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="contact-us">
                <div class="title">
                    <h3>联系我们</h3>
                    <p>Contact Us</p>
                </div>
                <div class="info" v-if="information">
					<p v-html="information.contentText"></p>
<!--                    <p>地址: {{ information.address }} </p>-->
<!--                    <p>电话: {{ information.telephone }}</p>-->
<!--                    <p>版权: {{ information.copyright }}</p>-->
<!--                    <p>网址: https://chn.yifanglab.com</p>-->
<!--&lt;!&ndash;                    <p>技术支持: support@yifanglab.con</p>&ndash;&gt;-->
<!--                    <p class="tel">全国统一客服热线: {{ information.telephone }}</p>-->
                </div>
                <div class="map" id="map">

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {BMPGL} from "@/bmpgl";
import {websiteContactDetail} from "@/api/CMSmodule";
import {websiteConfigDetail} from "@/api/home";

export default {
    name: "ContactUs",
    data() {
        return {
            ak: 'hvv31Hzt069G4NGGeDbpobdTOoDQhI3r',
            myMap: null,
			information:"",
        }
    },
    mounted() {
        this.initMap();
        this.getwebsiteConfigDetail() // 获取基础设置
    },
    methods: {
        initMap() {
            // 传入密钥获取地图回调。
            BMPGL(this.ak).then((BMapGL) => {
                // 创建地图实例
                let map = new BMapGL.Map("map");
                // 创建点坐标 axios => res 获取的初始化定位坐标
                let point = new BMapGL.Point(117.560862,39.101667)
                // 初始化地图，设置中心点坐标和地图级别
                map.centerAndZoom(point, 16)
                //开启鼠标滚轮缩放
                map.enableScrollWheelZoom(false)
                var marker = new BMapGL.Marker(point);  // 创建标注
                map.addOverlay(marker);
                var opts = {
                    width:280,
                    title: '天津一方科技有限公司'
                };
                var infoWindow = new BMapGL.InfoWindow('天津市滨海新区开发区西区新业八街18号', opts);
                let point2 = new BMapGL.Point(117.560862,39.102267)
                map.openInfoWindow(infoWindow, point);

            })
                .catch((err) => {
                    console.log(err)
                })
        },

		//  -------------------------接口请求
		getwebsiteConfigDetail(){ // 获取基础设置 关于一方
			websiteConfigDetail({tag:'contact_us'}).then((res) => {
				if (res.data.code==200) {
					this.information=res.data.data
					this.information.contentText=this.$utils.showHtml(this.information.contentText)
				}else{

				}

			})
		},


    }
}
</script>

<style scoped>

</style>
